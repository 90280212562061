import React, { useMemo } from 'react'
import cx from 'classnames'
import { useNavigation } from 'models/admin'

import { Href } from 'components/navigation'
import { Icon, IconName, Text } from 'components/dataDisplay'

import s from './MenuLink.scss'


export type MenuLinkItem<T> = T & {
  title: string
  icon: IconName
  count?: number
}

export type MenuLinkProps = MenuLinkItem<{ to?: string, toTab?: string, href?: string }> & {
  className?: string
  textClassName?: string
  subNav?: MenuLinkItem<{ to: string, toTab?: string, href?: string }>[]
}

const MenuLink: React.FC<MenuLinkProps> = (props) => {
  const { className, textClassName, to, toTab, href, title, icon, count } = props

  const { matchLink } = useNavigation()

  const isActive = matchLink(to || toTab || href)

  const countText = useMemo(() => {
    if (count > 99) {
      return '99+'
    }
    if (count > 0) {
      return String(count)
    }

    return ''
  }, [ count ])

  return (
    <Href
      className={cx(className, s.root, 'relative flex items-center pointer py-12px', {
        [s.active]: isActive,
      })}
      to={to}
      toTab={toTab}
      href={href}
    >
      <div className={s.indicator} />
      <div className={cx(s.text, textClassName, 'flex flex-1 items-center')}>
        {
          Boolean(icon) && (
            <Icon
              className="no-shrink"
              color="white"
              name={icon}
            />
          )
        }
        <Text
          className={cx('select-none', {
            'ml-12px': icon,
          })}
          size="c16"
          color="white"
          message={title}
        />
      </div>
      {
        Boolean(count) && (
          <Text
            className="mr-16px"
            message={countText}
            size="s13-r"
            color={isActive ? 'lebowski' : 'fargo'}
          />
        )
      }
    </Href>
  )
}


export default React.memo(MenuLink)
