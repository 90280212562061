import React from 'react'
import cx from 'classnames'

import s from './EditButtonsPanel.scss'


type Props = {
  className?: string
  bg?: 'amelie' | 'rush'
  wfull?: boolean
}

const EditButtonsPanel: React.FC<Props> = (props) => {
  const { className, bg = 'rush', wfull, children } = props

  return (
    <>
      <div style={{ marginBottom: '112px' }} />
      <div className={cx(className, s.buttonsPanel, 'flex pl-32px pr-40px pt-32px pb-40px', {
        'bg-rush': bg === 'rush',
        'bg-amelie': bg === 'amelie',
        [s.calcWidth]: !wfull,
        'w-full': wfull,
      })}>
        {children}
      </div>
    </>
  )
}

export default EditButtonsPanel