import React from 'react'
import cx from 'classnames'

import { Text } from 'components/dataDisplay'


const EditTitle = (props) => {
  const { className, message, notSaved } = props

  return (
    <div className={cx(className, 'flex')}>
      <Text
        size="h28"
        message={message}
        color="titanic"
      />
      {
        notSaved && (
          <Text
            className="ml-4px pb-16px"
            size="s13-r"
            message="не сохранено"
            color="fargo"
          />
        )
      }
    </div>
  )
}

export default EditTitle