import React, { useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import { useNavigation } from 'models/admin'

import { Href } from 'components/navigation'
import { Icon, Text } from 'components/dataDisplay'

import MenuLink, { MenuLinkProps } from '../MenuLink/MenuLink'

import s from './ToggleMenuLink.scss'


type ToggleMenuLink = MenuLinkProps & {
  isClosed?: boolean
  onMenuToggle?: (isMenuOpen: boolean) => void
}

const ToggleMenuLink: React.FC<ToggleMenuLink> = (props) => {
  const { className, textClassName, title, icon, count, subNav, isClosed, onMenuToggle } = props

  const { matchLink } = useNavigation()

  const [ isOpened, setOpened ] = useState(() => subNav.some(({ to, toTab, href }) => matchLink(to || toTab || href)))

  const menuToggleRef = useRef(onMenuToggle)
  menuToggleRef.current = onMenuToggle

  useEffect(() => {
    if (menuToggleRef.current) {
      menuToggleRef.current(isOpened)
    }
  }, [ isOpened ])

  useEffect(() => {
    if (isClosed) {
      setOpened(false)
    }
  }, [ isClosed ])

  const countText = useMemo(() => {
    if (count > 99) {
      return '99+'
    }
    if (count > 0) {
      return String(count)
    }

    return ''
  }, [ count ])

  return (
    <>
      <Href
        className={cx(className, s.root, 'flex items-center relative pointer py-12px')}
        onClick={(event) => {
          event.preventDefault()
          setOpened(!isOpened)
        }}
      >
        <div className={cx(s.text, textClassName, 'flex items-start')}>
          <Icon
            className="no-shrink"
            color="white-70"
            name={icon}
          />
          <Text
            className="ml-12px select-none"
            size="c16"
            color="white-70"
            message={title}
          />
        </div>
        <div className="ml-12px flex-1 flex items-center justify-start">
          <Icon
            className={cx(s.opacity, {
              'mr-36px': !count,
            })}
            name={isOpened ? 'arrow/arrow-up_16' : 'arrow/arrow-down_16'}
            preload={isOpened ? 'arrow/arrow-down_16' : 'arrow/arrow-up_16'}
            color="white-70"
          />
        </div>
        {
          Boolean(count) && (
            <Text
              className="mr-16px ml-12px"
              message={countText}
              size="s13-r"
              color="matrix"
            />
          )
        }
      </Href>
      {
        isOpened && (
          <>
            {
              subNav.map(({ title, to, toTab, href, icon, count }, index) => (
                <MenuLink
                  key={index}
                  className={s.subMenu}
                  textClassName={textClassName}
                  title={title}
                  to={to}
                  toTab={toTab}
                  href={href}
                  icon={icon}
                  count={count}
                />
              ))
            }
          </>
        )
      }
    </>
  )
}


export default React.memo(ToggleMenuLink)
